import { render, staticRenderFns } from "./experiment_discipline.vue?vue&type=template&id=302a4ccc&scoped=true&"
import script from "./experiment_discipline.vue?vue&type=script&lang=js&"
export * from "./experiment_discipline.vue?vue&type=script&lang=js&"
import style0 from "./experiment_discipline.vue?vue&type=style&index=0&id=302a4ccc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "302a4ccc",
  null
  
)

export default component.exports