<template>
  <b-card body-bg-variant="light"
          header="Experiment discipline"
          header-bg-variant="dark"
          header-text-variant="light">
    <label>Discipline</label>
    <b-form-select v-model="data.general.discipline"
                   :class="{ 'invalid': $data.$v.data.general.discipline.$error }"
                   :disabled="!editable"
                   @input="$data.$v.data.general.discipline.$touch()"
                   @input.native="reset_discipline_specific()">
      <option v-for="option in Object.keys(disciplines_prop)"
              :value="option">{{ option }}
      </option>
    </b-form-select>

    <div v-if="data.general.discipline === 'Others'">
      <label>Specific discipline</label>
      <b-form-input v-model="data.general.discipline_specific"
                    :class="{ 'invalid': $data.$v.data.general.discipline_specific.$error }"
                    :readonly="!editable"
                    placeholder="Enter missing value"
                    type="text"
                    @input="$data.$v.data.general.discipline_specific.$touch()"></b-form-input>
    </div>

    <div v-if="is_specific_discipline_empty">
      <label>Specific discipline</label>
      <b-form-select v-model="data.general.discipline_specific"
                     :class="{ 'invalid': $data.$v.data.general.discipline_specific.$error }"
                     :disabled="!editable"
                     @input="$data.$v.data.general.discipline_specific.$touch()">
        <option v-for="option in disciplines_prop[data.general.discipline]"
                :value="option">{{ option }}
        </option>
      </b-form-select>
    </div>

  </b-card>
</template>

<script>

  export default {
    name: 'experiment_discipline',
    props: {
      value: undefined,
      editable: true,
      disciplines_prop: undefined,
      validation: undefined,
    },
    computed: {
      is_specific_discipline_empty() {
        if (this.hasProperty(this.data.general, 'discipline')) {
          return (
            this.data.general.discipline !== 'Other' && this.disciplines_prop[this.data.general.discipline].length > 0
          );
        }
      },
    },
    data() {
      return {
        data: this.value,
        $v: this.validation,
      };
    },
    methods: {
      reset_discipline_specific() {
        if (this.data.general.discipline_specific !== undefined) {
          delete this.data.general.discipline_specific;
        }
      },
    },
    watch: {
      value: {
        handler() {
          this.data = this.value;
          if (this.data.general === undefined) {
            this.data.general = {};
          }
        },
        deep: true,
      },
    },
  };
</script>

<style scoped>
  .invalid {
    border: 2px solid red;
  }
</style>
