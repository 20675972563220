<template>
  <b-card body-bg-variant="light"
          header="Affiliation for the proposal"
          header-bg-variant="dark"
          header-text-variant="light">
    <label>Main author affiliation
      <p class="grey--text ma-0">If you don't have affiliation yet, please click Add Affiliation button</p></label>
    <b-form-select v-model="data.general.affiliation"
                   :disabled="!editable">
      <option v-for="affiliation in user_affiliations"
              :value="affiliation.organization_id">
        <p>{{ affiliation.organization.name }}</p>
        <p v-if="affiliation.department">{{ ' - ' + affiliation.department }}</p>
        <p v-if="affiliation.email">{{ ' <' + affiliation.email + '>' }}</p>
      </option>
    </b-form-select>

    <b-button variant="info"
              :disabled="!editable"
              @click="add_affiliation">
      Add Affiliation
    </b-button>
  </b-card>
</template>

<script>
  export default {
    name: 'affiliation',
    props: {
      value: undefined,
      editable: true,
    },
    data() {
      return {
        data: this.value,
        user_affiliations: undefined,
      };
    },
    methods: {
      load_data() {
        this.axios.get(`documents/${this.$route.params.id}/affiliations`)
          .then((response) => {
            this.user_affiliations = response.data;
          });
      },
      add_affiliation() {
        this.$emit('save_proposal');
        this.$router.push('/users/me');
      },
    },
    watch: {
      value: {
        handler() {
          this.data = this.value;
          if (this.data.general === undefined) {
            this.data.general = {};
          }
        },
        deep: true,
      },
    },
    created() {
      this.load_data();
    },
  };
</script>

<style scoped>

</style>
