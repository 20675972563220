<template>

  <div>
    <div v-for="field in fields"
         :key="field.model">
      <label>{{ field.label }}
        <span v-if="!field.is_required"
              class="grey--text font-italic"> - Optional</span>
        <p v-if="is_empty(field.restriction)"
           class="grey--text ma-0">{{ field.restriction }}</p>
      </label>
      <b-form-textarea v-if="field.is_required"
                       v-model="data[field.model]"
                       :class="{ 'invalid': $data.$v[field.model].$error }"
                       :disabled="!editable"
                       :rows="3"
                       placeholder="Enter missing value"
                       @input="$data.$v[field.model].$touch()">
      </b-form-textarea>
      <b-form-textarea v-else
                       v-model="data[field.model]"
                       :disabled="!editable"
                       :rows="3"
                       placeholder="Enter missing value">
      </b-form-textarea>
    </div>
  </div>

</template>

<script>

  export default {
    name: 'extended-text-area',
    props: {
      value: undefined,
      validation: undefined,
      editable: true,
      fields: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        data: this.value,
        $v: this.validation,
      };
    },
    methods: {
      is_empty(restriction) {
        return restriction !== '';
      },
    },
    watch: {
      value: {
        handler() {
          this.data = this.value;
          if (this.data === undefined) this.data = {};
        },
        deep: true,
      },
    },
  };
</script>

<style scoped>
  .invalid {
    border: 2px solid red;
  }
</style>
