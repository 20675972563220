<template>
  <label for="rapidAccessSubType"
         class="w-100">
    Rapid access subtype

    <template class="mt-1">
      <p v-for="option in rapidAccessSubtypes"
         :key="option.name"
         class="grey--text font-italic ma-0">
        {{ option.name }} - {{ option.description }}
      </p>
    </template>

    <b-form-select id="rapidAccessSubType"
                   v-model="proposal.general.rapid_access_subtype"
                   :class=" {'invalid': isInvalid.$error}"
                   :disabled="!editable"
                   :options="options"
                   class="mt-2"/>
  </label>
</template>

<script>
  import { importSpecification } from '@/helpers/proposalForm';

  export default {
    name: 'RapidAccessSubtype',
    props: {
      editable: {
        type: Boolean,
        default: true,
      },
      infrastructure: {
        type: String,
        default: 'synchrotron',
      },
      isInvalid: {
        type: Object,
        default: () => ({
          $error: false,
          $touch() { /* Nothing to be done here */ },
        }),
      },
      value: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        rapidAccessSubtypes: [],
        proposal: this.value,
      };
    },
    computed: {
      options() {
        return this.rapidAccessSubtypes.map((option) => option.name);
      },
    },
    watch: {
      'proposal.general': {
        handler() {
          this.isInvalid.$touch();
          this.$emit('input', this.proposal);
        },
        deep: true,
        immediate: true,
      },
      'value.general': {
        handler() {
          this.proposal = this.value;
        },
        deep: true,
      },
    },
    async created() {
      await this.getRapidAccessSubtypes();
    },
    methods: {
      async getRapidAccessSubtypes() {
        const specification = await importSpecification(this.infrastructure);
        this.rapidAccessSubtypes = specification.rapidAccessSubtypes;
      },
    },
  };
</script>

<style scoped>
  .invalid {
    border: 2px solid red;
  }
</style>
