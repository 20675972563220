<template>
  <base-icon-button v-on="$listeners"
                    :aria-label="title || ariaLabel"
                    :title="title"
                    class="ml-2 pa-0 background-color-red color-white">
    <base-material-design-icon class="color-white"
                               icon="close"/>
  </base-icon-button>
</template>

<script>
  import BaseIconButton from '@/components/baseComponents/baseIcons/BaseIconButton';
  import BaseMaterialDesignIcon from '@/components/baseComponents/baseIcons/BaseMaterialDesignIcon';

  export default {
    name: 'BaseIconButtonClose',
    components: { BaseMaterialDesignIcon, BaseIconButton },
    props: {
      title: {
        type: String,
        default: 'Delete',
      },
      ariaLabel: {
        type: String,
        default: 'Delete',
      },
    },
  };
</script>

<style scoped lang="scss">
  .color-white {
    color: white;
  }

  .background-color-red,
  .background-color-red:active,
  .background-color-red:focus,
  .background-color-red:active:focus,
  {
    background-color: $color-red-light;
  }
</style>
