<template>
  <b-card body-bg-variant="light"
          header="General information"
          header-bg-variant="dark"
          header-text-variant="light">

    <label>Title</label>
    <b-form-input v-model="data.title"
                  :class="{ 'invalid': $data.$v.data.title.$error }"
                  :placeholder="placeholders.input_placeholder"
                  :readonly="!editable"
                  type="text"
                  @input="$data.$v.data.title.$touch()"/>

    <label>Keywords<span v-if="infrastructure === 'synchrotron'" class="grey--text font-italic"> - Optional</span></label>
    <b-form-input v-model="data.general.keywords"
                  :placeholder="placeholders.input_placeholder"
                  :readonly="!editable"
                  :class="{ 'invalid': $data.$v.data.general.keywords.$error }"
                  type="text"
                  @input="$data.$v.data.general.keywords.$touch()"/>

    <label for="proposalType"
           style="width: 100%">
      Proposal type
      <b-form-select id="proposalType"
                     v-model="data.general.proposal_type"
                     :disabled="!editable"
                     :options="proposalTypes"
                     class="mt-2"/>
    </label>

    <div v-if="isProposalLongTerm">
      <p class="p-form-label">
        If your proposal is positively assessed but cannot be implemented as the long term proposal,
        do you agree to change the proposal category to standard (and consequently reduce experimental shifts)?
      </p>
      <extended-radio-group v-model="data.general.is_long_term_to_standard_transfer_consent"
                            :disabled="!editable"
                            :options="yesNo"
                            size="lg"/>
    </div>

    <div v-if="isProposalRapidAccess">
      <p class="p-form-label">
        Did you lose access to local infrastructure as a result of an irresistible external event (force majeure)?
      </p>
      <extended-radio-group v-model="data.general.is_rapid_access_force_majeure"
                            :disabled="!editable"
                            :options="yesNo"
                            size="lg"/>
    </div>

    <div v-if="isProposalRapidAccessForceMajeure">
      <p class="p-form-label">
        Would you like to perform your experiment remotely?
      </p>
      <extended-radio-group v-model="data.general.is_rapid_access_perform_remotely_requested"
                            :disabled="!editable"
                            :options="yesNo"
                            size="lg"/>
    </div>

    <rapid-access-subtype v-if="isProposalRapidAccess"
                          v-model="data"
                          :editable="editable"
                          :is-invalid="$data.$v.data.general.rapid_access_subtype"/>

    <label>Proposal category</label>
    <b-form-select v-model="data.general.category"
                   :disabled="!editable"
                   :isInvalid="$data.$v.data.category">

      <option v-for="category in filteredCategoriesProp"
              :key="category"
              :value="category"
              v-text="category"/>
    </b-form-select>

    <PreviousProposals v-if="has_previous_connotations"
                       v-model="data"
                       :editable="editable"
                       :validation="validation"/>

  </b-card>
</template>

<script>
  import extendedRadioGroup from '@/components/extended-radio-group';
  import RapidAccessSubtype from '@/components/proposal/proposals/general/general_information/RapidAccessSubtype';
  import placeholders from '@/json/placeholders';
  import { importSpecification, isProposalOfType } from '@/helpers/proposalForm';
  import yesNo from '@/json/radioButtonYesNo';

  export default {
    name: 'general_information',
    props: {
      value: {
        type: Object,
        required: true,
      },
      validation: {
        type: Object,
        required: true,
      },
      editable: {
        type: Boolean,
        default: true,
      },
      infrastructure: {
        type: String,
        required: true,
      },
    },
    components: {
      extendedRadioGroup,
      RapidAccessSubtype,
      PreviousProposals: () => import('@/components/proposal/proposals/general/general_information/PreviousProposals'),
    },
    data() {
      return {
        data: this.value,
        yesNo,
        $v: this.validation,
        placeholders,
        proposalTypes: [],
        proposalCategories: ['new', 'resubmission', 'continuation'],
      };
    },
    computed: {
      filteredCategoriesProp() {
        const isNotScientificMerit = this.data.general.rapid_access_subtype !== 'Scientific merit';
        if (this.isProposalRapidAccess && isNotScientificMerit) {
          return this.proposalCategories.filter((category) => category !== 'continuation');
        }
        return this.proposalCategories;
      },
      has_previous_connotations() {
        const categoriesWithPreviousProposals = [
          'resubmission',
          'continuation',
        ];
        const isSupplementaryMeasurement = this.data.general.rapid_access_subtype === 'Supplementary measurement';
        return isSupplementaryMeasurement || categoriesWithPreviousProposals.includes(this.data.general.category);
      },
      isProposalLongTerm() {
        return isProposalOfType(this.data.general, 'Long term');
      },
      isProposalRapidAccess() {
        return isProposalOfType(this.data.general, 'Rapid access');
      },
      isProposalRapidAccessForceMajeure() {
        return this.data.general.is_rapid_access_force_majeure;
      },
    },
    watch: {
      value: {
        handler() {
          this.data = this.value;
          if (this.data.general === undefined) {
            this.data.general = {};
          }
        },
        deep: true,
      },
      has_previous_connotations: {
        handler() {
          if (!this.has_previous_connotations) {
            delete this.data.general.previous_proposals;
          }
        },
      },
      data: {
        handler() {
          if (!this.isProposalRapidAccess) {
            delete this.data.general.rapid_access_subtype;
            delete this.data.general.is_rapid_access_force_majeure;
          }
          if (!('is_rapid_access_force_majeure' in this.data.general)
            || this.data.general.is_rapid_access_force_majeure === false) {
            delete this.data.general.is_rapid_access_perform_remotely_requested;
          }
          if (!this.isProposalLongTerm) {
            delete this.data.general.is_long_term_to_standard_transfer_consent;
          }
        },
        deep: true,
      },
    },
    async created() {
      await this.getProposalTypes();
    },
    methods: {
      async getProposalTypes() {
        const specification = await importSpecification(this.infrastructure);
        const { proposalTypesWithMaxShifts } = specification;
        this.proposalTypes = Object.keys(proposalTypesWithMaxShifts);
      },
    },
  };
</script>

<style scoped>
  .invalid {
    border: 2px solid red;
  }
</style>
