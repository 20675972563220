<template>
  <b-card body-bg-variant="light"
          header="Equipment"
          header-bg-variant="dark"
          header-text-variant="light">

    <label>Usage of SOLARIS laboratory</label>
    <extended-radio-group v-model="data.general.use_solaris_lab"
                          :disabled="!editable"
                          :options="yesNo"
                          size="lg"></extended-radio-group>

    <extended_text_area v-if="data.general.use_solaris_lab"
                        v-model="data.general"
                        :editable="editable"
                        :fields="description_fields"
                        :validation="$data.$v.data.general"/>

  </b-card>
</template>

<script>
  import yesNo from '@/json/radioButtonYesNo';
  import extendedRadioGroup from '@/components/extended-radio-group';
  import extended_text_area from '@/components/extended-text-area';

  export default {
    name: 'equipment',
    props: {
      value: undefined,
      validation: undefined,
      editable: true,
    },
    components: {
      extendedRadioGroup,
      extended_text_area,
    },
    data() {
      return {
        data: this.value,
        yesNo,
        $v: this.validation,
        description_fields: [
          {
            label: 'Processes (sample preparation) performed by users during the experiment at SOLARIS',
            is_required: false,
            restriction: '',
            model: 'process_in_lab',
          },
          {
            label: 'Equipment and products requested from the facility',
            is_required: false,
            restriction: '',
            model: 'solaris_equipment',
          },
          {
            label: 'Equipment and products to be provided by the user',
            is_required: false,
            restriction: 'Including safety precautions to operate equipment '
              + '(e.g. electrically safe, secured overheating, explosion secured)',
            model: 'user_equipment',
          },
        ],
      };
    },
    watch: {
      value: {
        handler() {
          this.data = this.value;
          if (this.data.general === undefined) this.data.general = {};
        },
        deep: true,
      },
      'data.general.use_solaris_lab': function () {
        if (this.data.general.use_solaris_lab === false) {
          delete this.data.general.user_equipment;
          delete this.data.general.solaris_equipment;
          delete this.data.general.process_in_lab;
        }
      },

    },
  };
</script>

<style scoped>

</style>
