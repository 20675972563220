<template>
  <b-card body-bg-variant="light"
          header="Published papers by the proposers as a result of experiment/s done at the SOLARIS"
          header-bg-variant="dark"
          header-text-variant="light">

    <p class="p-form-label">Have you published any research in the last 4 years as a result of experiment/s performed using the SOLARIS infrastructure?</p>
    <extended-radio-group v-model="data.general.any_publications_related_to_solaris"
                          :disabled="!editable"
                          :options="yesNo"
                          size="lg"/>

    <template v-if="shouldPublicationsFieldBeVisible">
      <p class="p-form-label">Please give the references of papers published by the proposers during the past 4 years</p>
      <p class="p-form-description"
         v-text="publicationPattern"/>

      <base-input-tags-list v-model="data.general.publications"
                            :editable="editable"
                            :isInvalid="$data.$v.data.general.publications"
                            :should-add-tag-button-be-disabled="shouldAddPublicationButtonBeDisabled"
                            class="mb-3">
        <template v-slot:inputAppend>
          <extended-radio-group id="publications-source"
                                v-model="singlePublicationSource"
                                :disabled="!editable"
                                :options="publicationSourceOptions"
                                class="mr-2"
                                size="md"/>
        </template>
      </base-input-tags-list>
    </template>
  </b-card>
</template>

<script>
  import yesNo from '@/json/radioButtonYesNo';
  import extendedRadioGroup from '@/components/extended-radio-group';
  import BaseInputTagsList from '@/components/baseComponents/BaseInputTagsList';

  export default {
    name: 'PublicationsSection',
    props: {
      value: {
        type: Object,
        required: true,
      },
      validation: {
        type: Object,
        required: true,
      },
      editable: {
        type: Boolean,
        default: true,
      },
    },
    components: {
      extendedRadioGroup,
      BaseInputTagsList,
    },
    data() {
      return {
        data: this.value,
        $v: this.validation,
        singlePublicationSource: '',
        yesNo,
        publicationPattern: 'Pattern: Author(s) Last Name and First Name, Title of Article, Journal Title, '
          + 'Volume and issue number, Page numbers, (Publication date), DOI link',
        publicationSourceOptions: [
          {
            text: 'SOLARIS infrastructure ONLY',
            value: 'data from SOLARIS infrastructure ONLY',
            buttonVariant: 'outline-info',
          },
          {
            text: 'More than one source',
            value: 'data from more than one source',
            buttonVariant: 'outline-info',
          },
        ],
      };
    },
    computed: {
      shouldPublicationsFieldBeVisible() {
        return this.data.general.any_publications_related_to_solaris;
      },
      publicationsLength() {
        return this.data.general.publications?.length;
      },
      shouldAddPublicationButtonBeDisabled() {
        return !this.singlePublicationSource;
      },
    },
    watch: {
      value: {
        handler() {
          this.data = this.value;
          if (this.data.general === undefined) {
            this.data.general = {};
          }
        },
        deep: true,
      },
      'data.general.any_publications_related_to_solaris': {
        handler() {
          if (!this.data.general.any_publications_related_to_solaris) {
            delete this.data.general.publications;
          }
        },
        deep: false,
      },
      publicationsLength: {
        handler() {
          if (this.singlePublicationSource) {
            let lastElement = this.data.general.publications[this.publicationsLength - 1];
            lastElement = `${lastElement} - ${this.singlePublicationSource}`;
            this.singlePublicationSource = '';
            this.data.general.publications[this.publicationsLength - 1] = lastElement;
          }
        },
      },
    },
  };
</script>

<style scoped>
  ::v-deep #publications-source #radio-group {
    margin-bottom: 0 !important;
  }
</style>
