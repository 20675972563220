<template>
  <b-card body-bg-variant="light"
          header="Additional information"
          header-bg-variant="dark"
          header-text-variant="light">
    <p class="p-form-label">Is this proposal research performed as part of some theses?</p>
    <extended-radio-group v-model="data.general.any_diploma_theses_related_to_proposal"
                          :disabled="!editable"
                          :options="yesNo"
                          :validation="$data.$v.data.general"
                          size="lg"/>

    <template v-if="shouldNumberOfDiplomaThesesFieldBeVisible">
      <p class="p-form-label">Please specify how many theses of a given type are related to this proposal</p>
      <b-input-group :class="{ 'invalid': $data.$v.data.general.number_of_diploma_theses.$error }"
                     class="mt-2"
                     prepend="Specify number of theses">
        <b-form-input v-for="(key, index) in diplomaThesesOptions"
                      :key="index"
                      v-model="data.general.number_of_diploma_theses[key]"
                      :min="0"
                      :placeholder="key"
                      :readonly="!editable"
                      style="text-overflow: ellipsis"
                      type="number"
                      @input="$data.$v.$touch()"/>
      </b-input-group>
    </template>

    <p class="p-form-label">Do you have an experience with this type of experiment?</p>
    <extended-radio-group v-model="data.general.experiment_experience"
                          :disabled="!editable"
                          :options="yesNo"
                          size="lg"/>

    <extended-text-area v-if="data.general.experiment_experience"
                        v-model="data.general"
                        :editable="editable"
                        :fields="experimentExperienceDescriptionOptions"
                        :validation="$data.$v.data.general"/>

    <p class="p-form-label">Does this proposal have any industrial involvement or sponsorship?</p>
    <p class="p-form-description">This information is used only for statistical purposes. Your answer will not affect your proposal</p>
    <extended-radio-group v-model="data.general.industrial_sponsorship"
                          :disabled="!editable"
                          :options="yesNo"
                          :validation="$data.$v.data.general"
                          size="lg"/>
  </b-card>
</template>

<script>
  import extendedRadioGroup from '@/components/extended-radio-group';
  import extendedTextArea from '@/components/extended-text-area';
  import yesNo from '@/json/radioButtonYesNo';

  export default {
    name: 'additional_info',
    components: {
      extendedRadioGroup,
      extendedTextArea,
    },
    props: {
      value: undefined,
      validation: undefined,
      editable: true,
    },
    data() {
      return {
        data: this.value,
        $v: this.validation,
        diplomaThesesOptions: [
          "No. bachelor's/engineering theses",
          "No. master's theses",
          'No. doctoral theses',
          'No. habilitation theses',
        ],
        experimentExperienceDescriptionOptions: [{
          label: 'Where did you get your experience with experiments of this type?',
          is_required: true,
          restriction: '',
          model: 'experience_description',
        }],
        yesNo,
      };
    },
    computed: {
      shouldNumberOfDiplomaThesesFieldBeVisible() {
        return this.data.general.any_diploma_theses_related_to_proposal;
      },
    },
    watch: {
      value: {
        handler() {
          this.data = this.value;
          if (this.data.general === undefined) {
            this.data.general = {};
          }
        },
        deep: true,
      },
      'data.general.experiment_experience': {
        handler() {
          if (!this.data.general.experiment_experience) {
            delete this.data.general.experience_description;
          }
        },
        deep: false,
      },
      'data.general.any_diploma_theses_related_to_proposal': {
        handler(currentValue) {
          if (currentValue) {
            if (!this.data.general.number_of_diploma_theses) {
              this.$set(this.data.general, 'number_of_diploma_theses', {});
            }
          } else {
            delete this.data.general.number_of_diploma_theses;
          }
        },
      },
      'data.general.number_of_diploma_theses': {
        handler(currentValue) {
          if (currentValue) {
            const excludedValues = ['', '0'];
            const entriesFiltered = Object.entries(currentValue).filter(([, value]) => !excludedValues.includes(value));
            const currentValueFiltered = Object.fromEntries(entriesFiltered);
            if (Object.keys(currentValue).length !== Object.keys(currentValueFiltered).length) {
              // eslint-disable-next-line camelcase
              this.data.general.number_of_diploma_theses = currentValueFiltered;
            }
          }
        },
        deep: true,
      },
    },
  };
</script>

<style scoped>
  .invalid {
    border: 2px solid red;
    border-radius: 5px;
  }
</style>
