<template>
  <label class="w-100"
         for="shiftsSchedule">
    Shifts schedule
    <br/>
    <span class="grey--text ma-0">
        Your proposition of distributing requested shifts into {{ numberOfCallsToPlan }} upcoming calls.<br>
        <span v-if="isInfrastructure('cryoem')">Allowed options for each call are
          {{ getLongTermShiftOptionsForEachCall() }} shifts.<br>
        </span>
        Scheduled number of shifts has to be equal to number of shifts requested.
      </span>
    <b-input-group id="shiftsSchedule"
                   :class="{ 'invalid': $data.$v.$error }"
                   class="mt-2"
                   prepend="Desired shifts in following calls">
      <b-form-input v-for="(iterator, index) in numberOfCallsToPlan"
                    :key="iterator"
                    v-model="shiftsSchedule[index]"
                    :max="maxShiftsPerCall"
                    :min="minShiftsPerCall"
                    :placeholder="`Call nr ` + iterator"
                    :readonly="!editable"
                    :step="stepOfDesiredShiftsInCall"
                    type="number"
                    @input="$data.$v.$touch()"
                    @update="updateShiftsSchedule(index, $event)"/>
    </b-input-group>
  </label>
</template>

<script>
  import { importSpecification } from '@/helpers/proposalForm';

  export default {
    name: 'ShiftsSchedule',
    props: {
      stepOfDesiredShiftsInCall: {
        type: Number,
        required: false,
        default: 1,
      },
      editable: {
        type: Boolean,
        required: true,
      },
      infrastructure: {
        type: String,
        required: true,
      },
      shiftsScheduleValidation: {
        type: Object,
        required: true,
      },
      value: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        minShiftsPerCall: 0,
        maxShiftsPerCall: 0,
        numberOfCallsToPlan: 0,
        shiftsSchedule: this.value,
        $v: this.shiftsScheduleValidation,
      };
    },
    watch: {
      value: {
        handler() {
          this.shiftsSchedule = this.value;
        },
      },
    },
    async created() {
      await this.getLongTermSpecification();
    },
    methods: {
      async getLongTermSpecification() {
        const specification = await importSpecification(this.infrastructure);
        const { longTermSpecification } = specification;
        this.minShiftsPerCall = longTermSpecification.minShiftsPerCall;
        this.maxShiftsPerCall = longTermSpecification.maxShiftsPerCall;
        this.numberOfCallsToPlan = longTermSpecification.numberOfCallsToPlan;
      },
      updateShiftsSchedule() {
        this.$emit('input', this.shiftsSchedule);
      },
      isInfrastructure(infrastructure) {
        return this.infrastructure === infrastructure;
      },
      getLongTermShiftOptionsForEachCall() {
        return Array.from(
          { length: Math.floor((this.maxShiftsPerCall - this.minShiftsPerCall) / 2) + 1 },
          (_, i) => this.minShiftsPerCall + i * 2,
        )
          .filter((num) => num <= this.maxShiftsPerCall)
          .join(', ');
      },
    },
  };
</script>

<style scoped>
  .invalid {
    border: 2px solid red;
    border-radius: 5px;
  }
</style>
