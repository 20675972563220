function createCommunicate(value, remaining_fields_to_communicate_map) {
  let category = '';
  let fieldText = '';
  Object.keys(remaining_fields_to_communicate_map)
    .forEach(
      (field_category) => {
        if (value in remaining_fields_to_communicate_map[field_category]) {
          category = field_category;
          fieldText = remaining_fields_to_communicate_map[field_category][value];
        }
      },
    );
  return {
    category,
    fieldText,
  };
}

export const missingFields = {
  methods: {
    process_missing_fields(fields_to_process, remaining_fields_to_communicate_map) {
      const processed_fields = {};
      Object.values(fields_to_process)
        .forEach(
          (value) => {
            const userCommunicate = createCommunicate(value, remaining_fields_to_communicate_map);

            if (!processed_fields[userCommunicate.category]) {
              processed_fields[userCommunicate.category] = [];
            }

            processed_fields[userCommunicate.category].push({
              text: userCommunicate.fieldText,
              value,
            });
          },
        );

      return processed_fields;
    },
  },

};
