import _ from 'lodash';
import { isProposalOfType } from '@/helpers/proposalForm';

const is_no_empty_field = function (val) {
  if (val) {
    return val.every((first) => first.every((second) => second !== ''));
  }
  return true;
};

const is_between = function (val, min, max) {
  return (max >= val && val >= min);
};

const isPositiveNumber = (value) => {
  if (value?.length > 0) {
    return parseFloat(value) > 0;
  }
  return true;
};

const areAllShiftsScheduled = (value, siblings) => {
  if (isProposalOfType(siblings, 'Long term')) {
    const numberOfShiftsToSchedule = Number(siblings.shifts);
    const scheduledShiftsArray = _.values(value).map(Number);
    const scheduledShiftsNumber = _.sum(scheduledShiftsArray);
    return scheduledShiftsNumber === numberOfShiftsToSchedule;
  }
  return true;
};

const checkNumberSuperiority = (smallerValue, graterValue) => parseFloat(smallerValue) <= parseFloat(graterValue);

const isAnyThesisSelected = (value) => {
  const numberOfDiplomaThesisList = Object.values(value || {});
  if (numberOfDiplomaThesisList.length) {
    const numberOfAllThesis = numberOfDiplomaThesisList.reduce((acc, el) => acc + Number(el), 0);
    return numberOfAllThesis > 0;
  }
  return true;
};

const hasPreviousConnotations = (proposal) => {
  const categoriesWithPreviousProposals = [
    'resubmission',
    'continuation',
  ];
  const isSupplementaryMeasurement = proposal.general.rapid_access_subtype === 'Supplementary measurement';
  return isSupplementaryMeasurement || categoriesWithPreviousProposals.includes(proposal.general.category);
};

export {
  is_no_empty_field,
  is_between,
  isPositiveNumber,
  areAllShiftsScheduled,
  checkNumberSuperiority,
  isAnyThesisSelected,
  hasPreviousConnotations,
};
