var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-tags',{staticClass:"pl-0 pr-0 b-form-tags",attrs:{"disabled":!_vm.editable,"no-outer-focus":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var tags = ref.tags;
var inputAttrs = ref.inputAttrs;
var inputHandlers = ref.inputHandlers;
var addTag = ref.addTag;
var removeTag = ref.removeTag;
return [_c('b-input-group',{staticClass:"mb-0"},[_c('b-form-input',_vm._g(_vm._b({staticClass:"form-control",class:{'invalid': _vm.isInvalid.$error && !inputAttrs.value},attrs:{"placeholder":_vm.placeholderText},on:{"input":function($event){return _vm.isInvalid.$touch()}}},'b-form-input',inputAttrs,false),inputHandlers)),_c('b-input-group-append',[_vm._t("inputAppend"),_c('b-button',{attrs:{"disabled":_vm.checkIfAddTagButtonShouldBeDisabled(inputAttrs.value),"variant":"info"},domProps:{"textContent":_vm._s(_vm.btnText)},on:{"click":function($event){return addTag()}}})],2)],1),_c('base-tags-list',{attrs:{"editable":_vm.editable,"removeTag":removeTag,"tags":tags,"tagBodyClass":"customTagBodyStyle","tagCardClass":"customTagCardStyle","variant":"white"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var tagContent = ref.tagContent;
return [_c('span',{staticClass:"mr-2",domProps:{"textContent":_vm._s(tagContent)}})]}}],null,true)})]}}],null,true),model:{value:(_vm.data),callback:function ($$v) {_vm.data=$$v},expression:"data"}})}
var staticRenderFns = []

export { render, staticRenderFns }