<template>
  <div v-if="tags.length"
       class="list-inline ma-0">

    <b-card v-for="tag in tags"
            :key="tag"
            :bg-variant="variant"
            body-class="pa-0 pl-2"
            class="ma-0 mt-1 mr-2 tagCard"
            :class=$attrs.tagCardClass>

      <b-card-body class="pa-0 pl-2 tagBody"
                   :class=$attrs.tagBodyClass>
        <slot :tagContent="tag"/>
        <base-icon-button-close :disabled="!editable"
                                @click="removeTag(tag)"/>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
  import BaseIconButtonClose from '@/components/baseComponents/baseIcons/BaseIconButtonClose';

  export default {
    name: 'BaseTagsList',
    components: { BaseIconButtonClose },
    props: {
      editable: {
        type: Boolean,
        default: true,
      },
      removeTag: {
        type: Function,
        required: true,
      },
      tags: {
        type: Array,
        required: true,
      },
      variant: {
        type: String,
        default: 'info',
        validator(value) {
          return ['primary',
                  'secondary',
                  'success',
                  'warning',
                  'danger',
                  'info',
                  'light',
                  'dark',
                  'white',
                  'transparent'].includes(value);
        },
      },
    },
  };
</script>

<style scoped>
  .tagBody {
    justify-content: space-between;
    align-items: center;
  }

  .tagCard {
    color: white;
    display: inline-flex;
  }
</style>
