<template>
  <div>
    <b-table :fields="fields"
             :items="attachments_data"
             head-variant="light"
             hover
             small
             stacked="md">
      <template v-slot:cell(filename)="data">
        <div v-if="is_document_in_db">
          <a :href="axios.defaults.baseURL + '/documents/download/' + data.item.id"
             target="_blank">
            {{ data.item.filename }}
          </a>
        </div>
        <div v-else>
          <span>
            {{ data.item.filename }}
          </span>
        </div>
      </template>

      <template v-slot:cell(action)="data">
        <base-icon-button-delete v-if="!disabled"
                                 title="Remove attachment"
                                 @click="remove_file(data.item)"/>
      </template>
    </b-table>

    <b-form-file ref="file_input"
                 :accept="extensions"
                 :disabled="disabled || attachment_disabled"
                 :placeholder="placeholders.attachment_placeholder"
                 variant="info"
                 @change="save_file"></b-form-file>
    <label v-if="format_mismatch"
           class="red--text mt-1">
      {{ placeholders.wrong_attachment_type }}
    </label>
  </div>
</template>

<script>
  import placeholders from '@/json/placeholders';
  import BaseIconButtonDelete from '@/components/baseComponents/baseIcons/BaseIconButtonDelete';

  export default {
    components: { BaseIconButtonDelete },
    props: {
      value: {
        required: true,
        type: Array,
        default: () => [],
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      attachment_url: {
        required: true,
        type: String,
      },
      multiple: {
        type: Boolean,
        default: false,
      },
      extensions: {
        type: String,
        default: 'application/pdf',
      },
    },
    computed: {
      attachment_disabled() {
        if (!this.multiple && this.attachments_data) {
          return this.attachments_data.length > 0;
        }
        return false;
      },
    },
    data() {
      return {
        fields: ['filename', 'length', 'action'],
        attachments: this.value,
        attachments_data: undefined,
        format_mismatch: false,
        is_document_in_db: !!this.attachment_url,
        placeholders,
      };
    },
    methods: {
      isFileSizeInBytesBelowThresholdInMB(fileSizeInBytes, mbThreshold) {
        const bytesInMegabyte = 1048576;
        const megabytes = fileSizeInBytes / bytesInMegabyte;
        return megabytes <= mbThreshold;
      },
      save_file(e) {
        if (this.extensions.includes(e.target.files[0].type)) {
          const file = (e.target.files || e.dataTransfer.files)[0];
          if (this.isFileSizeInBytesBelowThresholdInMB(file.size, 10)) {
            this.format_mismatch = false;
            if (this.is_document_in_db) {
              this.upload_file(file);
            } else {
              this.attachments.push(file);
            }
          } else {
            this.$refs.file_input.reset();
            this.format_mismatch = true;
            this.$notify({ type: 'error', title: 'Could not save because the file is too big!' });
          }
        } else {
          this.$refs.file_input.reset();
          this.format_mismatch = true;
        }
      },
      upload_file(file) {
        this.axios.put(
          `${this.attachment_url}/attachments/${file.name}`,
          file,
          { headers: { 'Content-Type': file.type } },
        )
          .then((response) => {
            const { msg, error, data } = response.data;
            if (msg) {
              this.$notify({ type: 'success', title: msg });
              this.attachments = data;
            } else {
              this.$notify({ type: 'error', title: error });
            }
          })
          .catch(() => {
            this.$notify({ type: 'error', title: 'Something went wrong' });
          });
      },
      load_data_from_cache() {
        this.attachments_data = [];
        this.attachments.forEach(({ name: filename, size: length }) => {
          this.attachments_data.push({ filename, length });
        });
      },
      remove_file(file) {
        if (this.is_document_in_db) {
          this.remove_file_from_db(file);
        } else {
          this.remove_file_from_cache(file);
        }
      },
      remove_file_from_db(file) {
        this.axios.delete(`${this.attachment_url}/attachments/${file.id}`)
          .then(
            (response) => {
              this.$refs.file_input.reset();
              this.attachments = response.data.data;
            },
          );
      },
      remove_file_from_cache(file) {
        this.$refs.file_input.reset();
        this.attachments = this.attachments.filter((el) => el.name !== file.filename);
        this.load_data_from_cache();
      },
      load_data() {
        if (this.is_document_in_db) {
          this.load_data_from_db();
        } else {
          this.load_data_from_cache();
        }
      },
      load_data_from_db() {
        this.axios.get(`${this.attachment_url}/attachments`)
          .then((response) => {
            this.attachments_data = response.data;
          })
          .catch(() => {
            this.$notify({ type: 'error', title: 'Something went wrong' });
          });
      },
    },
    created() {
      this.load_data();
    },
    watch: {
      value() {
        this.attachments = this.value;
        this.load_data();
      },
      attachments() {
        this.$emit('input', this.attachments);
      },
    },
  };
</script>

<style lang="scss"
       scoped>

</style>
