<template>
  <b-form-tags v-model="data"
               :disabled="!editable"
               class="pl-0 pr-0 b-form-tags"
               no-outer-focus>
    <template v-slot="{ tags, inputAttrs, inputHandlers, addTag, removeTag }">
      <b-input-group class="mb-0">
        <b-form-input :class="{'invalid': isInvalid.$error && !inputAttrs.value}"
                      :placeholder="placeholderText"
                      class="form-control"
                      v-bind="inputAttrs"
                      @input="isInvalid.$touch()"
                      v-on="inputHandlers"/>

        <b-input-group-append>
          <slot name="inputAppend"/>
          <b-button :disabled="checkIfAddTagButtonShouldBeDisabled(inputAttrs.value)"
                    variant="info"
                    @click="addTag()"
                    v-text="btnText"/>
        </b-input-group-append>
      </b-input-group>

      <base-tags-list :editable="editable"
                      :removeTag="removeTag"
                      :tags="tags"
                      tagBodyClass="customTagBodyStyle"
                      tagCardClass="customTagCardStyle"
                      variant="white">
        <template v-slot="{ tagContent }">
            <span class="mr-2"
                  v-text="tagContent"/>
        </template>
      </base-tags-list>

    </template>
  </b-form-tags>
</template>

<script>
  import BaseTagsList from '@/components/baseComponents/BaseTagsList';

  export default {
    name: 'BaseInputTagsList',
    components: { BaseTagsList },
    props: {
      value: {
        type: Array,
        default: () => [],
      },
      btnText: {
        type: String,
        default: 'Add publication',
      },
      placeholderText: {
        type: String,
        default: 'Enter publication',
      },
      editable: {
        type: Boolean,
        default: true,
      },
      isInvalid: {
        type: Object,
        default: () => ({
          $error: false,
          $touch() { /* Nothing to be done here */
          },
        }),
      },
      shouldAddTagButtonBeDisabled: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return { data: this.value };
    },
    methods: {
      checkIfAddTagButtonShouldBeDisabled(inputValue) {
        return !inputValue || this.shouldAddTagButtonBeDisabled;
      },
    },
    watch: {
      value: {
        handler() {
          this.$set(this, 'data', this.value);
        },
      },
      data: {
        handler() {
          this.$emit('input', this.data);
        },
      },
    },
  };
</script>

<style lang="scss"
       scoped>
  ::v-deep .customTagCardStyle {
    margin-top: 0.75rem !important;
    margin-bottom: 0.25rem !important;
    width: 100%;
    word-wrap: break-word;
  }

  ::v-deep .customTagBodyStyle {
    display: flex;
    min-height: 2rem;
    color: dimgray;
    padding-right: 0.25rem !important;
  }

  .b-form-tags {
    border: unset;
    background-color: transparent;
  }

  .invalid {
    border: 2px solid red;
  }
</style>
