<template>
  <b-card body-bg-variant="light"
          header="Scientific part"
          header-bg-variant="dark"
          header-text-variant="light">

    <p class="p-form-label">
      Attachment <br/>
      <span class="grey--text"
         style="margin: 0">
        Fill scientific part template and attach as PDF. Max size 10MB
      </span>
    </p>
    <attachment-input v-model="data.general.attachments"
                      :attachment_url="'/documents/' + $route.params.id"
                      :disabled="!editable"
                      class="mt-1"/>

    <div class="mt-3">
      <a :href="wordTemplateUrl">
        Download MS Word template for Scientific part
      </a>
    </div>

  </b-card>
</template>

<script>
  import attachmentInput from '@/components/attachment-input';

  export default {
    name: 'ScientificPart',
    props: {
      value: undefined,
      validation: undefined,
      editable: {
        type: Boolean,
        default: true,
      },
    },
    components: { attachmentInput },
    data() {
      return {
        // eslint-disable-next-line vue/max-len
        wordTemplateUrl: 'https://synchrotron.uj.edu.pl/documents/1457771/155442410/Scientific-part-template/58d2a43e-1916-4e9d-9aa4-1ea7930335e7',
        data: this.value,
        $v: this.validation,
      };
    },
    watch: {
      value: {
        handler() {
          this.data = this.value;
          if (this.data.general === undefined) this.data.general = {};
        },
        deep: true,
      },
    },
  };
</script>

<style scoped>

</style>
