<template>
  <div class="text-center">
    <b-button-group>
      <b-btn v-if="tabIndex > 0"
             @click="decrementTabIndex">
        Previous
      </b-btn>
      <b-btn v-if="tabIndex < lastTabIndex"
             @click="incrementTabIndex">
        Next
      </b-btn>
    </b-button-group>
    <b-button-group class="ml-2">
      <b-btn v-if="isProposalSubmittable"
             variant="info"
             @click="submitProposal">
        SUBMIT
      </b-btn>
    </b-button-group>
  </div>
</template>

<script>
  import { isProposalOfType } from '@/helpers/proposalForm';

  export default {
    name: 'ProposalFormFooter',
    model: { prop: 'tabIndex' },
    props: {
      endStation: {
        type: String,
        required: true,
      },
      isMissingListEmpty: {
        type: Boolean,
        required: true,
      },
      lastTabIndex: {
        type: Number,
        default: 3,
      },
      proposal: {
        type: Object,
        required: true,
      },
      proposalState: {
        type: String,
        required: true,
      },
      tabIndex: {
        type: Number,
        default: 0,
      },
    },
    computed: {
      isCallOpen() {
        return this.$store.getters['calls/current_call'];
      },
      isEndStationInCallEnvironments() {
        const currentCallEndStations = this.$store.getters['calls/current_call_end_stations'];
        return currentCallEndStations.includes(this.endStation);
      },
      isProposalSubmittable() {
        if (this.isProposalRapidAccess) {
          return this.areBaseSubmitRequirementsMet;
        }
        const callRequirements = this.isCallOpen && this.isEndStationInCallEnvironments;
        return this.areBaseSubmitRequirementsMet && callRequirements;
      },
      areBaseSubmitRequirementsMet() {
        return this.isLastTabSelected && !this.isProposalSubmitted && this.isMissingListEmpty;
      },
      isLastTabSelected() {
        return this.tabIndex === this.lastTabIndex;
      },
      isProposalRapidAccess() {
        return isProposalOfType(this.proposal.general, 'Rapid access');
      },
      isProposalSubmitted() {
        return this.proposalState === 'submitted';
      },
    },
    methods: {
      decrementTabIndex() {
        this.$emit('input', this.tabIndex - 1);
      },
      emitLoadData() {
        this.$emit('load-data');
      },
      incrementTabIndex() {
        this.$emit('input', this.tabIndex + 1);
      },
      async submitProposal() {
        const submitData = { objectId: this.$route.params.id, proposal: this.proposal };
        const response = await this.$store.dispatch('proposalForm/submitProposal', submitData);
        if (response) {
          this.emitLoadData();
        }
      },
    },
  };
</script>

<style scoped>

</style>
